import type { Pagination } from '~/composables/types';
import type { CustomerOrders, CustomerOrder, OrderItemInterface, TaxItem, CustomerOrderGiftWrap } from '~/modules/GraphQL/types';

export const getDate = (order: CustomerOrder): string => new Date(order?.order_date?.replace(/ /g, 'T')).toLocaleDateString();
export const getSubTotal = (order: CustomerOrder): number => order?.total?.subtotal?.value ?? 0;
export const getSubTotalInclTax = (order: CustomerOrder): number => order?.total?.subtotal_incl_tax?.value ?? 0;
export const getTaxes = (order: CustomerOrder): TaxItem[] => order?.total?.taxes ?? [];
export const getDiscounts = (order: CustomerOrder): number => order?.total?.discounts?.length
  ? order?.total?.discounts.reduce((totalDiscount, discount) => totalDiscount + discount.amount.value, 0)
  : 0;
export const getBaseGrandTotal = (order: CustomerOrder): number => order?.total?.base_grand_total?.value ?? 0;
export const getGrandTotal = (order: CustomerOrder): number => order?.total?.grand_total.value ?? 0;
export const getOrderTotal = (order: CustomerOrder): number => order?.grand_total ?? 0;
export const getShippingTotal = (order: CustomerOrder): number => order?.total?.shipping_handling.total_amount.value ?? 0;
export const getOrderCurrency = (order: CustomerOrder): string => order?.total?.subtotal.currency ?? 'USD';
export const getItemPrice = (item: OrderItemInterface): number => item?.product_sale_price?.value ?? 0;
export const getOrderPaymentMethod = (order: CustomerOrder): string => order?.payment_methods[0].name ?? '';
export const getGiftWrapPrice = (order: CustomerOrder): string => order.total.gift_wrap.amount ?? '';
export const getGiftWrapLabel = (order: CustomerOrder): string => order.total.gift_wrap.label ?? '';

const getPagination = (orders: CustomerOrders): Pagination => ({
  currentPage: orders?.page_info?.current_page || 1,
  totalPages: orders?.page_info?.total_pages || 1,
  totalItems: orders?.total_count || 0,
  itemsPerPage: orders?.page_info?.page_size || 10,
  pageOptions: [10, 50, 100],
});

const orderGetters = {
  getDate,
  getSubTotal,
  getSubTotalInclTax,
  getTaxes,
  getDiscounts,
  getShippingTotal,
  getBaseGrandTotal,
  getGrandTotal,
  getOrderTotal,
  getOrderCurrency,
  getItemPrice,
  getOrderPaymentMethod,
  getPagination,
  getGiftWrapPrice,
  getGiftWrapLabel
};

export default orderGetters;
